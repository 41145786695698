import logotranparenten from "../images/logo-transparen.png";

const Logotop = () =>
{
    return (
        <div className="flex items-center justify-center">
            <div className="pt-2">
            <img
                src={logotranparenten}
                alt=":("
            />
            </div>
        </div>
    );
}

export default Logotop;